import store from '@/store'
import router from '@/router'
import { foundryRequest } from '@/config/authConfig';
import { ref, getCurrentInstance } from '@vue/composition-api'
import { getVuetify } from '@core/utils'

export default function useAcademyDashboard() {

    const vm = getCurrentInstance().proxy
    const completedCourseStudentData = ref([])

    
    const fetchAcademyAnalytics = async (params) => {
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        store
          .dispatch('app-academy-dashboard/fetchAcademyAnalytics', {
            ...params
          })
          .then(response => {
            completedCourseStudentData.value = response.data
            
          })
          .catch(error => {
            console.log(error)
            if (error.response.status === 404) {
                completedCourseStudentData.value = []
            }
            if (error.response.status === 401) router.push({ name: 'misc-not-authorized' })
          })
    }

    return {
        completedCourseStudentData,


        fetchAcademyAnalytics
    }
  

}