import axios from '@axios'
import store from '@/store'
import { transformRequestOptions } from '@core/utils'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchAcademyAnalytics(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                .get(`/academy/analytics_detail/`,{
                    params: queryParams,
                    paramsSerializer: params => transformRequestOptions(params)
                  })
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)})
            })
        },
        
    }
}
