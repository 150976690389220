<template>
    <v-row class="match-height">
        <v-col
        cols="12"
        md="12"
        >
        <v-card>
            <v-card-title>Completed Courses</v-card-title>
            <v-card-text>
                <course-student-pivot-table
                    :data="completedCourseStudentData"
                    :reducer="reducer"
                    :reduceAttr="reduceAttr"
                ></course-student-pivot-table>
            </v-card-text>
        </v-card>
        </v-col>
    </v-row>
  
</template>

<script>
import { onUnmounted, ref, getCurrentInstance, onMounted } from '@vue/composition-api'
import useAcademyDashboard from './useAcademyDashboard'
import store from '@/store'
import academyStoreModule from './academyStoreModule'
import CourseStudentPivotTable from './CourseStudentPivotTable.vue'

export default {
    components: {
        CourseStudentPivotTable,
    },
    setup() {
        const vm = getCurrentInstance().proxy

        const ACADEMY_DASHBOARD_APP_STORE_MODULE_NAME = 'app-academy-dashboard'
    
        // Register module
        if (!store.hasModule(ACADEMY_DASHBOARD_APP_STORE_MODULE_NAME)) store.registerModule(ACADEMY_DASHBOARD_APP_STORE_MODULE_NAME, academyStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(ACADEMY_DASHBOARD_APP_STORE_MODULE_NAME)) store.unregisterModule(ACADEMY_DASHBOARD_APP_STORE_MODULE_NAME)
        })


        const {
            completedCourseStudentData,
            fetchAcademyAnalytics
        } = useAcademyDashboard()

        

        const reduceAttr = ref('count')
            
        const reducer = (sum, item, rows, cols) => {
            return sum + item[reduceAttr.value] || sum ;
        }

        onMounted (() => {
            fetchAcademyAnalytics()
        })

        return {
            completedCourseStudentData,
            reduceAttr,
            reducer
        }
    }

}
</script>

<style>

</style>